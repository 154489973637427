import React from "react";
import OCLogo from "../logo.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="w-full grid grid-flow-col auto-cols-auto gap-2 text-xs py-[1rem]">
      <div className="flex flex-col justify-center">
        <img src={OCLogo} alt="Oasis Commodities Logo" className="w-fit h-fit" />
      </div>
      <div className="hidden md:flex flex-col justify-end">
        <Link to="/Policy" className="pb-[1rem]">Privacy Policy & GDPR</Link>
      </div>
      <div className="hidden md:flex flex-col justify-end">
        <Link onClick={() => (window.location = "mailto:kenneth@oasisc.co")} className="pb-[1rem]">
          Contact
        </Link>
      </div>
      <div className="flex flex-col justify-end text-right">
        <div className="pb-[1rem]">OasisC ©2023</div>
      </div>
    </div>
  );
}
