import React from "react";
import { Link } from "react-router-dom";
import Market from "../imgs/market.png";
import RightArrow from "../imgs/right-arrow.svg";

export default function GetInTouch() {
  return (
    <div className="w-full h-full grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-6 md:gap-20 md:pt-0">
      <div className="flex flex-col justify-center text-center">
        <img src={Market} alt="Market" className="flex flex-col justify-end" />
      </div>

      <div className="flex flex-col justify-center">
        <div className="py-10 w-full md:w-3/4 grid grid-flow-row auto-rows-auto gap-2">
          <div className="flex flex-col justify-center text-xl lg:text-2xl">
            <p className="font-title text-black">
              Oasis Commodities house leads the market in supplying commodities
              to the energy, steel, and industrial sectors.
            </p>
          </div>

          <Link
            onClick={() => (window.location = "mailto:kenneth@oasisc.co")}
            className="relative group overflow-hidden h-12 rounded-full flex space-x-2 items-center"
          >
            <div className="text-blue flex flex-row">
              <p>Get in touch</p>
              <div className="pl-3 flex flex-col justify-center animate-pulse">
                <img src={RightArrow} alt="Down arrow" />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
