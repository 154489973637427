import React from "react";
import Fertilizers from "../imgs/products-ferilizers.png";
import RareEarth from "../imgs/products-rare-earth.png";
import OilRefined from "../imgs/products-oil-refined.png";
import GreenCarbon from "../imgs/products-green.png";
import Pharma from "../imgs/products-pharma.png";
import CementTimber from "../imgs/products-cement-timber.png";

export default function CoreProducts() {
  return (
    <div id="CoreProducts">
      {/* Title / Text */}
      <div className="flex justify-center">
        <div className="w-full md:w-2/3 grid grid-flow-row auto-rows-auto gap-2">
          <div className="flex flex-col justify-center text-center">
            <p className="font-title text-blue text-2xl md:text-4xl">
              Core Products.
            </p>
          </div>
          <div className="flex flex-col justify-center text-center">
            <p className="text-sm md:text-base">
              Oasis Commodities is at the forefront of the global power, cement,
              steel and industrial bulk commodity markets. Our extensive
              relationships with producers, consumers and logistic providers
              alike, as well as our risk management and financing capabilities,
              allows us to provide a one stop shop solution for our clients'
              needs.
            </p>
          </div>
        </div>
      </div>

      {/* Product - images */}
      <div className="pt-[1rem] px-0 md:px[5rem] lg:px-[10rem]">
        <div className="flex flex-col justify-center text-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
            <div className="flex flex-col justify-center text-center">
              {/* Fertilizers and Raw Materials */}
              <div className="bg-blue">
                <div className="h-[90%]">
                  <img
                    src={Fertilizers}
                    alt="Fertilizers and Raw Materials"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="h-[10%] flex flex-col justify-center">
                  <span className="text-sm md:text-xs">
                    Fertilizers and Raw Materials
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center text-center">
              {/* Rare Earth Metals */}
              <div className="bg-blue lg:text-base">
                <div className="h-[90%]">
                  <img
                    src={RareEarth}
                    alt="Rare Earth Metals"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="h-[10%] flex flex-col justify-center">
                  <span className="text-sm md:text-xs">Rare Earth Metals</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center text-center">
              {/* Oil and Refined Products */}
              <div className="bg-blue lg:text-base">
                <div className="h-[90%]">
                  <img
                    src={OilRefined}
                    alt="Oil and Refined Products"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="h-[10%] flex flex-col justify-center">
                  <span className="text-sm md:text-xs">
                    Oil and Refined Products
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center text-center">
              {/* Green Carbon Products */}
              <div className="bg-blue lg:text-base">
                <div className="h-[90%]">
                  <img
                    src={GreenCarbon}
                    alt="Green Carbon Products"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="h-[10%] flex flex-col justify-center">
                  <span className="text-sm md:text-xs">
                    Green Carbon Products
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center text-center">
              {/* Pharmaceutical Products */}
              <div className="bg-blue lg:text-base">
                <div className="h-[90%]">
                  <img
                    src={Pharma}
                    alt="Pharmaceutical Products"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="h-[10%] flex flex-col justify-center">
                  <span className="text-sm md:text-xs">
                    Pharmaceutical Products
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center text-center">
              {/* Cement and Timber */}
              <div className="bg-blue lg:text-base">
                <div className="h-[90%]">
                  <img
                    src={CementTimber}
                    alt="Cement and Timber"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="h-[10%] flex flex-col justify-center">
                  <span className="text-sm md:text-xs">Cement and Timber</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
