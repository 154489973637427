import React from 'react'

export default function PolicyGDPR() {
  return (
    <div className='py-[3rem] grid grid-flow-row auto-row-auto gap-5'>
        <div className='font-title text-xl'>Privacy Policy & GDPR</div>
        <p>This privacy policy sets out how OasisC Limited uses and protects any information that you give us when you use this website.</p>
        <p>We are committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
        <p>We may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>
        <p className='font-bold'>What we collect</p>
        <p>We may collect the following information:</p>
        <ul className='list-disc list-inside'>
            <li>name</li>
            <li>contact email address</li>
        </ul>
        <p className='font-bold'>What we do with the information we gather</p>
        <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
        <ul className='list-disc list-inside'>
            <li>Internal record keeping</li>
            <li>We may use the information to improve our products and services</li>
            <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided</li>
            <li>From time to time, we may also use your information to contact you for market research purposes. We may use the information to customise the website according to your interests</li>
        </ul>
        <p className='font-bold'>Security</p>
        <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
        <p className='font-bold'>How we use cookies</p>
        <p>Our site uses cookies to collect information about your computer, including your IP address, operating system and browser type, for system administration and in order to create reports.</p>
        <p>Google Analytics is a web analytics tool that helps us understand how visitors engage with our website. We then use the information to compile reports and to help us improve our site. Google Analytics collects information anonymously. It reports website trends without identifying individual visitors.</p>
        <p>You can opt out of Google Analytics without affecting how you visit our site – for more information on opting out of being tracked by Google Analytics across all websites you use, <a href="https://support.google.com/analytics/answer/181881?hl=en&ref_topic=2919631">visit this Google page</a>.</p>
        <p className='font-bold'>Links to other websites</p>
        <p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
        <p className='font-bold'>GDPR Privacy Statement</p>
        <p>Please refer to our GDPR Privacy Statement for information about your rights regarding your personal data.</p>
    </div>
  )
}
